// Import libraries.
import React from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Trans } from "@lingui/macro";

// Import types.
import TeamInfo from "types/models/TeamInfo";
import { formatCompanyStatusColor, formatCompanyStatusLabel } from "types/enums/CompanyStatus";
import AccountStatus, { formatAccountStatusColor, formatAccountStatusLabel } from "types/enums/AccountStatus";
import ServiceStatus, { formatServiceStatusColor, formatServiceStatusLabel } from "types/enums/ServiceStatus";

// Import utilities.
import StringUtils from "utils/String";
import NumberFormatter from "utils/formatters/Number";

// Import components.
import { Link, Typography } from "@mui/material";
import ImageWrapper from "components/common/ImageWrapper";
import NoTeamImage from "components/icons/NoTeamImage";
import LabelWithCopy from "components/common/LabelWithCopy";
import EllipsisInCenter from "components/common/EllipsisInCenter";
import FixBillingLink from "components/common/widgets/FixBillingLink";

//Import Icons.
import ErrorIcon from "@mui/icons-material/Error";
interface PROPS extends WithStyles<typeof styles>, RouteComponentProps {
    teamInfo: TeamInfo | null;
    isTeamAdmin: boolean;
}
class TeamBanner extends React.PureComponent<PROPS> {
    render() {
        const { classes, teamInfo, isTeamAdmin } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.card} style={{ flexDirection: "row", justifyContent: "flex-start", borderRadius: "0 .5em .5em 0", borderLeft: 0 }}>
                    <ImageWrapper
                        style={{ width: "4.5em", height: "4.5em", marginRight: "0.5em" }}
                        src={!StringUtils.isNullOrEmpty(teamInfo?.imageUrl) ? "files/portal/c/" + teamInfo?.companyId + teamInfo?.imageUrl : null}
                        fallback={<NoTeamImage />}
                        disableCache={teamInfo?.updatedAt || null}
                    />

                    <div style={{ display: "flex", flexDirection: "column", overflow: "hidden", justifyContent: "space-between", flex: 1 }}>
                        <EllipsisInCenter
                            id={"companyName"}
                            labelStyle={{ fontSize: "0.85em", fontWeight: "bold" }}
                            showCopyIcon
                            value={teamInfo ? teamInfo.companyName : "---"}
                            customValueToCopy={`${teamInfo?.companyName} (${teamInfo?.accountNumber})`}
                        />

                        <LabelWithCopy id={"accountNumber"} copyOnHover={true} labelStyle={{ fontSize: "0.85em", fontWeight: "bold" }} value={teamInfo ? teamInfo.accountNumber : null}>
                            {teamInfo ? teamInfo.accountNumber : "---"}
                        </LabelWithCopy>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography noWrap style={{ color: formatCompanyStatusColor(teamInfo?.companyStatus), fontSize: "0.85em" }}>
                                {formatCompanyStatusLabel(teamInfo?.companyStatus, true)}
                            </Typography>

                            {teamInfo?.accountCredits != null && teamInfo.accountCredits > 0 && (
                                <Typography style={{ fontSize: "0.85em" }}>
                                    <Trans>Credit: </Trans> {NumberFormatter.formatCurrency(teamInfo.accountCredits)}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>

                <div className={classes.card}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>
                            <Trans>Contact</Trans>
                        </Typography>

                        <Typography noWrap>{teamInfo?.contactName || teamInfo?.contactEmail}</Typography>
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>
                            <Trans>Members</Trans>
                        </Typography>

                        <Typography noWrap>{teamInfo?.memberCount}</Typography>
                    </span>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>
                            <Trans>Apps</Trans>
                        </Typography>

                        <Typography>{teamInfo?.appIds?.length}</Typography>
                    </span>
                </div>

                <div className={classes.card}>
                    <Link data-id={`billing`} style={{ fontWeight: "bold", fontSize: "0.85em" }} onClick={() => this.props.history.push("/team/setup/team-info")}>
                        <Trans>Billing</Trans>
                    </Link>

                    <div className={classes.nameValuePair} style={{ marginLeft: "1em" }}>
                        <Typography noWrap>
                            <Trans>Billing:</Trans>
                        </Typography>

                        {teamInfo?.serviceStatus !== ServiceStatus.DRAFT ? (
                            <Typography style={{ color: "var(--label-positive-color)" }} noWrap>
                                <Trans>Created</Trans>
                            </Typography>
                        ) : (
                            <Typography style={{ color: "var(--label-negative-color)" }} noWrap>
                                <Trans>Not Created</Trans>
                            </Typography>
                        )}
                    </div>

                    <div className={classes.nameValuePair} style={{ marginLeft: "1em" }}>
                        <Typography noWrap>
                            <Trans>Payment:</Trans>
                        </Typography>

                        {teamInfo?.accountHasPaymentMethods ? (
                            <Typography style={{ color: "var(--label-positive-color)" }} noWrap>
                                <Trans>Configured</Trans>
                            </Typography>
                        ) : (
                            <Typography style={{ color: "var(--label-negative-color)" }} noWrap>
                                <Trans>Not Configured</Trans>
                            </Typography>
                        )}
                    </div>
                </div>

                {isTeamAdmin && (
                    <div className={classes.card}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <Typography style={{ fontWeight: "bold", fontSize: "0.85em" }}>
                                <Trans>Status</Trans>
                            </Typography>
                            {(teamInfo?.accountStatus === AccountStatus.POOR_STANDING || teamInfo?.serviceStatus === ServiceStatus.SUSPENDED) && (
                                <ErrorIcon style={{ color: "var(--label-negative-color, inherit)", alignSelf: "center", marginRight: "0.25em", height: "0.85em", width: "0.85em" }} />
                            )}
                        </span>
                        <div className={classes.nameValuePair} style={{ marginLeft: "1em" }}>
                            <Typography noWrap>
                                <Trans>Accounting:</Trans>
                            </Typography>

                            <Typography style={{ color: formatAccountStatusColor(teamInfo?.accountStatus), whiteSpace: "nowrap", minWidth: "8em" }} noWrap>
                                {formatAccountStatusLabel(teamInfo?.accountStatus)}
                            </Typography>
                            {teamInfo?.accountStatus === AccountStatus.POOR_STANDING && <FixBillingLink teamInfo={teamInfo} />}
                        </div>

                        <div className={classes.nameValuePair} style={{ marginLeft: "1em" }}>
                            <Typography noWrap>
                                <Trans>Service:</Trans>
                            </Typography>

                            <Typography style={{ color: formatServiceStatusColor(teamInfo?.serviceStatus), whiteSpace: "nowrap", minWidth: "8em" }} noWrap>
                                {formatServiceStatusLabel(teamInfo?.serviceStatus)}
                            </Typography>
                            {teamInfo?.serviceStatus === ServiceStatus.SUSPENDED && <FixBillingLink teamInfo={teamInfo} />}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            gap: "0.5em",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
            "@media(min-width: 450px) and (max-width: 1420px)": {
                "& > *:nth-child(4)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 1160px)": {
                "& > *:nth-child(3)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 900px)": {
                "& > *:nth-child(2)": {
                    display: "none",
                },
            },
        },
        card: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            border: "1px solid var(--secondary-border-color, inherit)",

            padding: "0.5em",
            borderRadius: "0.5em",
            minWidth: "16em",
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            "& > .MuiTypography-root": {
                fontSize: "0.85em",
            },
            "& > *:first-child": {
                minWidth: "10em",
            },
            "& > *:not(:first-child)": {
                fontWeight: "bold",
            },
        },
    });

export default withRouter(withStyles(styles)(TeamBanner));

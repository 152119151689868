//Import data
import availableLanguages from "assets/data/availableLanguages";

export interface Languages {
    defaultLanguageCode: string;
    supportedLanguageCodes: string[];
}

export interface LanguagesList {
    label: React.ReactNode;
    code: string;
}

export const getLanguageNamesbyCodes = (data: string[]): LanguagesList[] => {
    if (data && Array.isArray(data)) return data.map((code) => ({ code, label: getLanguageNamebyCode(code) }));

    return [];
};

export const getLanguageNamebyCode = (code: string): React.ReactNode => {
    return availableLanguages.find((item: any) => item.code === code)?.label;
};

import React from "react";
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

/**
 * Represents data associated with a particular Screen.
 *
 * Typically these refer to "primary" screens (i.e. the screen that is associated with a particular PortalRoute).
 *
 * However, we should be able to define additional ScreenData for other things (so long as the ID does not refer to a route path).
 * This would allow data to be assiociated with Dialog content for example or specific sections within a "primary" Screen" (a Card or Table).
 *
 * Eventually, I believe this could also be used for nested routers. I haven't played with nested routers very much but the concept would be
 * the same as the "primary" router (the only caveat being that the id's or path's should NOT overlap, meaning route paths defined by different
 * routers would be expected to be mutually exclusive).
 *
 * - THE ABOVE NESTED-ROUTER SCENARIO WILL REQUIRE MORE THOUGHT AND EXPERIMENTATION (this would be considered advanced usage).
 *     - One thought would be to add a "tag" or "category" to the ScreenData to help organize and find the correct ScreenData.
 *
 * Represented Data:
 *   - id: The unique identifier for the data (typically refers to a route path).
 *   - title: The title of the screen.
 *   - infoBox: Contains data related to the infobox component shown on each Screen.
 *     - text: The actual text that is displayed within the infobox.
 *     - learnMore: The URL used for the "Learn More" button (optional).
 *   - search: Contains data related to the universal search component.
 *     - title: The actual title that is searched against and subsequently displayed in the universal search results.
 */
export interface ScreenData {
    id: string;
    title: (i18n: I18n) => string;
    infobox?: {
        text?: React.ReactNode;
        learnMore?: string | null;
    };
    search?: {
        title?: (i18n: I18n) => string;
    };
    pageKey?: string | null;
    breadcrumbs?: {
        label: React.ReactNode;
        route?: string;
    }[];
}

const superDashboardBreadcrumb = { label: <Trans>Super</Trans>, route: "/super/dashboard" };
const teamDashboardBreadcrumb = { label: <Trans>Team</Trans>, route: "/team/dashboard" };
const appDashboardBreadcrumb = { label: <Trans>App</Trans>, route: "/app/dashboard" };
const userDashboardBreadcrumb = { label: <Trans>User</Trans>, route: "/user/user-browser" };

const manageBreadcrumb = { label: <Trans>Manage</Trans> };
const configureBreadcrumb = { label: <Trans>Configure</Trans> };
const developBreadcrumb = { label: <Trans>Develop</Trans> };
const metricsBreadcrumb = { label: <Trans>Metrics</Trans> };
const securityBreadcrumb = { label: <Trans>Security</Trans> };
const setupBreadcrumb = { label: <Trans>Setup</Trans> };
const designBreadcrumb = { label: <Trans>Design</Trans> };
const coreAppInfoBreadcrumb = { label: <Trans>Core App Info</Trans> };
const adminToolsBreadcrumb = { label: <Trans>Admin Tools</Trans> };
const authenticationBreadcrumb = { label: <Trans>Authentication</Trans> };
const cloudCodeBreadcrumb = { label: <Trans>Cloud Code</Trans> };
const cloudDataBreadcrumb = { label: <Trans>Cloud Data</Trans> };
const filesBreadcrumb = { label: <Trans>Files</Trans> };
const gamificationBreadcrumb = { label: <Trans>Gamification</Trans> };
const groupsBreadcrumb = { label: <Trans>Groups</Trans> };
const integrationsBreadcrumb = { label: <Trans>Integrations</Trans> };
const leaderboardsBreadcrumb = { label: <Trans>Leaderboards</Trans> };
const marketplaceBreadcrumb = { label: <Trans>Marketplace</Trans> };
const messagingBreadcrumb = { label: <Trans>Messaging</Trans> };
const multiplayerBreadcrumb = { label: <Trans>Multiplayer</Trans> };
const notificationsBreadcrumb = { label: <Trans>Notifications</Trans> };
const promotionsBreadcrumb = { label: <Trans>Promotions</Trans> };
const segmentationBreadcrumb = { label: <Trans>Segmentation</Trans> };
const serversBreadcrumb = { label: <Trans>Servers</Trans> };
const superBreadcrumb = { label: <Trans>Super</Trans> };
const portalBreadcrumb = { label: <Trans>Portal</Trans> };
const globalBreadcrumb = { label: <Trans>Global</Trans> };
const globalDataBreadcrumb = { label: <Trans>Global Data</Trans> };
const logsBreadcrumb = { label: <Trans>Logs</Trans> };
const summaryBreadcrumb = { label: <Trans>Summary</Trans> };
const dataBreadcrumb = { label: <Trans>Data</Trans> };
const socialBreadcrumb = { label: <Trans>Social</Trans> };
const reportsBreadcrumb = { label: <Trans>Reports</Trans> };
const usageBreadcrumb = { label: <Trans>Usage</Trans> };
const exportsBreadcrumb = { label: <Trans>Exports</Trans> };

export const getScreenDataForId = (id: string): ScreenData | null => {
    return allScreens.find((item) => item.id.trim().toLowerCase() === id.trim().toLowerCase()) || null;
};

export const getScreenDataForPageKey = (pageKey: string): ScreenData | null => {
    return allScreens.find((item) => item.pageKey?.trim().toLowerCase() === pageKey.trim().toLowerCase()) || null;
};

export const superScreens: ScreenData[] = [
    {
        id: "/super/dashboard",
        title: (i18n: I18n) => i18n._(t`Super Dashboard`),
        infobox: {
            text: <Trans>View high level system stats and trends.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Dashboard`),
        },
        breadcrumbs: [{ label: <Trans>Super</Trans> }],
    },
    {
        id: "/super/manage/apps",
        title: (i18n: I18n) => i18n._(t`Manage Apps`),
        infobox: {
            text: <Trans>Monitor the performance of apps using the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Apps`),
        },
        breadcrumbs: [superDashboardBreadcrumb, manageBreadcrumb, { label: <Trans>Apps</Trans> }],
    },
    {
        id: "/super/manage/teams",
        title: (i18n: I18n) => i18n._(t`Manage Teams`),
        infobox: {
            text: <Trans>View and manage the list of teams. Note that you can delete whole teams here. Be careful!</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Teams`),
        },
        breadcrumbs: [superDashboardBreadcrumb, manageBreadcrumb, { label: <Trans>Teams</Trans> }],
    },
    {
        id: "/super/manage/users",
        title: (i18n: I18n) => i18n._(t`Manage Members`),
        infobox: {
            text: <Trans>View and manage the list of users of the system (i.e. developers / team members). Note that Super Users are managed here as well.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Manage > Users`),
        },
        breadcrumbs: [superDashboardBreadcrumb, manageBreadcrumb, { label: <Trans>Manage Members</Trans> }],
    },
    {
        id: "/super/configure/branding",
        title: (i18n: I18n) => i18n._(t`Configure Branding`),
        infobox: {
            text: <Trans>Customize the global look and feel of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Branding`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Branding</Trans> }],
    },
    {
        id: "/super/configure/caches",
        title: (i18n: I18n) => i18n._(t`Configure Caches`),
        infobox: {
            text: <Trans>Use with caution - the caches increase performance of the API Servers.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Caches`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Caches</Trans> }],
    },
    {
        id: "/super/configure/config",
        title: (i18n: I18n) => i18n._(t`Configure View Configuration`),
        infobox: {
            text: <Trans>Used to view the low-level configuration of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Config`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Config</Trans> }],
    },
    {
        id: "/super/configure/libraries",
        title: (i18n: I18n) => i18n._(t`Configure Libraries`),
        infobox: {
            text: <Trans>Customize the list of client libraries that are available to download.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Libraries`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Libraries</Trans> }],
    },
    {
        id: "/super/configure/properties",
        title: (i18n: I18n) => i18n._(t`Configure Properties`),
        infobox: {
            text: <Trans>Customize the behaviour of the system.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Properties`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Properties</Trans> }],
    },
    {
        id: "/super/configure/screens",
        title: (i18n: I18n) => i18n._(t`Configure Screens`),
        infobox: {
            text: <Trans>Used to enable/disable select screens globally across the system. You can also override screens on an app-by-app basis.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Configure > Screens`),
        },
        breadcrumbs: [superDashboardBreadcrumb, configureBreadcrumb, { label: <Trans>Screens</Trans> }],
    },
    {
        id: "/super/develop/web-request-debugger",
        title: (i18n: I18n) => i18n._(t`Web Request Debugger`),
        infobox: {
            text: <Trans>Used to call / debug server requests before the web screens have been created.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Develop > Web Request Debugger`),
        },
        breadcrumbs: [superDashboardBreadcrumb, developBreadcrumb, { label: <Trans>Web Request Debugger</Trans> }],
    },
    {
        id: "/super/logs/audit-log",
        title: (i18n: I18n) => i18n._(t`Audit Logs`),
        infobox: {
            text: (
                <Trans>
                    The audit log helps you to keep track of changes to the meta-data of system. <b>Actions that update data are shown in bold.</b>
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Audit Log`),
        },
        breadcrumbs: [superDashboardBreadcrumb, logsBreadcrumb, { label: <Trans>Audit Log</Trans> }],
    },
    {
        id: "/super/logs/errors",
        title: (i18n: I18n) => i18n._(t`Error Logs`),
        infobox: {
            text: <Trans>Displays a log of errors and warnings recorded on the system across all applications.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Errors`),
        },
        breadcrumbs: [superDashboardBreadcrumb, logsBreadcrumb, { label: <Trans>Errors</Trans> }],
    },
    {
        id: "/super/logs/unreadable-requests",
        title: (i18n: I18n) => i18n._(t`Unreadable Request Logs`),
        infobox: {
            text: (
                <Trans>
                    These are requests received that the system is unable to act upon. Usually caused by networking errors (we didn't receive the full request), bad serialization of JSON objects or security issues (bad packet id, secret, etc.).
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Logs > Unreadable Requests`),
        },
        breadcrumbs: [superDashboardBreadcrumb, logsBreadcrumb, { label: <Trans>Unreadable Requests</Trans> }],
    },
    {
        id: "/super/metrics/team-usage",
        title: (i18n: I18n) => i18n._(t`Team Usage Metrics`),
        infobox: {
            text: <Trans>This screen breaks down brainCloud usage by Team - and provides a handy calculator for proportionately distributing costs.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`SUPER > Metrics > Team Usage`),
        },
        breadcrumbs: [superDashboardBreadcrumb, metricsBreadcrumb, { label: <Trans>Team Usage</Trans> }],
    },
];

export const teamScreens: ScreenData[] = [
    {
        id: "/team/dashboard",
        title: (i18n: I18n) => i18n._(t`Team Dashboard`),
        infobox: {
            text: <Trans>View high level team stats and trends.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Dashboard`),
        },
        breadcrumbs: [{ label: <Trans>Team</Trans> }],
    },
    {
        id: "/team/manage/apps",
        title: (i18n: I18n) => i18n._(t`Manage Apps`),
        infobox: {
            text: <Trans>Manage the list of Applications in your account. This is also where you create new Apps.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/8927397-team-manage-apps",
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Manage > Apps`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, manageBreadcrumb, { label: <Trans>Apps</Trans> }],
    },
    {
        id: "/team/manage/members",
        title: (i18n: I18n) => i18n._(t`Manage Members`),
        infobox: {
            text: <Trans>Manage the list of team members in your account.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/8928055-team-manage-members",
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Manage > Members`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, manageBreadcrumb, { label: <Trans>Members</Trans> }],
    },
    {
        id: "/team/security/permissions",
        title: (i18n: I18n) => i18n._(t`Permissions`),
        infobox: {
            text: <Trans>This section allows you to manage the role definitions and assignments on a per-app basis for your team. Note that the system currently supports only pre-defined roles.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/8931474-team-security-permissions",
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Security > Permissions`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, securityBreadcrumb, { label: <Trans>Permissions</Trans> }],
    },
    {
        id: "/team/security/audit-log",
        title: (i18n: I18n) => i18n._(t`Audit Log`),
        infobox: {
            text: (
                <Trans>
                    The audit log helps you to keep track of changes to the meta-data of your apps. <b>Actions that update data are shown in bold.</b>
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/8940925-manage-security-audit-log",
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Security > Audit Log`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, securityBreadcrumb, { label: <Trans>Audit Log</Trans> }],
    },
    {
        id: "/team/setup/team-info",
        title: (i18n: I18n) => i18n._(t`Team Info`),
        infobox: {
            text: <Trans>Edit the key contact and billing information for your Team account.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/8951006-team-setup-team-info",
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Setup > Team Info`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, setupBreadcrumb, { label: <Trans>Team Info</Trans> }],
    },
    {
        id: "/team/setup/branding",
        title: (i18n: I18n) => i18n._(t`Team Branding`),
        infobox: {
            text: <Trans>Customize the look and feel of the system for individual teams.</Trans>,
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`TEAM > Setup > Branding`),
        },
        breadcrumbs: [teamDashboardBreadcrumb, setupBreadcrumb, { label: <Trans>Branding</Trans> }],
    },
];

export const appScreens: ScreenData[] = [
    {
        id: "/app/dashboard",
        title: (i18n: I18n) => i18n._(t`App Dashboard`),
        infobox: {
            text: (
                <Trans>
                    Welcome! <br />
                    This is your <strong>App’s Home Page</strong> - it gives you quick links to everything you need to get up and running!
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`APP > Dashboard`),
        },
        pageKey: "APP_DASHBOARD",
        breadcrumbs: [{ label: <Trans>App</Trans> }],
    },
    {
        id: "/app/design/core-app-info/application-ids",
        title: (i18n: I18n) => i18n._(t`Application IDs`),
        infobox: {
            text: (
                <Trans>
                    Key application identifiers. These identifiers are used when connecting your <b>client</b> to the platform, or for allowing the platform to connect to <b>third party services</b>.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/8951327-design-core-app-info-application-ids",
        },
        pageKey: "DESIGN_APPIDS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Application IDs</Trans> }],
    },
    {
        id: "/app/design/core-app-info/advanced-settings",
        title: (i18n: I18n) => i18n._(t`Advanced Settings`),
        infobox: {
            text: <Trans>These settings control key aspects of the platform's behaviour. Be careful!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/8958224-design-core-app-info-advanced-settings",
        },
        pageKey: "DESIGN_ADVANCED",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Advanced Settings</Trans> }],
    },
    {
        id: "/app/design/core-app-info/auto-delete-users",
        title: (i18n: I18n) => i18n._(t`Auto-Delete Users`),
        infobox: {
            text: (
                <Trans>
                    Dormant users are those that haven't logged into the app in the past twelve months. These users provide little to no value, and can add significant storage costs. The feature allows the developer to automatically delete dormant
                    users to avoid additional storage costs.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/8978611-design-core-app-info-auto-delete-users",
        },
        pageKey: "DESIGN_AUTO_DELETE",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Auto-Delete Users</Trans> }],
    },
    {
        id: "/app/design/core-app-info/archive-settings",
        title: (i18n: I18n) => i18n._(t`Archive Settings`),
        infobox: {
            text: <Trans>Save a temporary backup of a user account before deleting it for any reason. Account is saved in custom entities collection of your choice. A Plus Plan is required.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9010215-design-core-app-info-archive-settings",
        },
        pageKey: "DESIGN_USER_ARCHIVE",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Archive Settings</Trans> }],
    },
    {
        id: "/app/design/core-app-info/localization",
        title: (i18n: I18n) => i18n._(t`Localization`),
        infobox: {
            text: <Trans>Choose the languages that your application supports here - as well as the default language to use when communicating with your users (should their native language not be supported).</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9010481-design-core-app-info-localization",
        },
        pageKey: "DESIGN_LOCALIZATION",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Localization</Trans> }],
    },
    {
        id: "/app/design/core-app-info/peer-publish",
        title: (i18n: I18n) => i18n._(t`Peer Publish`),
        infobox: {
            text: <Trans>Publishing an app as a Peer Service allows other apps to utilize its features as part of their own implementation.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9011758-design-core-app-info-peer-publish",
        },
        pageKey: "DESIGN_PEER_PUBLISH",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Peer Publish</Trans> }],
    },
    {
        id: "/app/design/core-app-info/platforms",
        title: (i18n: I18n) => i18n._(t`Platforms`),
        infobox: {
            text: (
                <Trans>
                    The platform performs two sets of version checks when your client connects. The first checks the version of the client library being used - and ensures that it is compatible. The second allows the developer to specify a minimum
                    version of their client application itself (ensuring that users are forced to upgrade obsolete versions of your client application).
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9016918-design-core-app-info-platforms",
        },
        pageKey: "DESIGN_PLATFORMS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Platforms</Trans> }],
    },
    {
        id: "/app/design/core-app-info/security",
        title: (i18n: I18n) => i18n._(t`Security`),
        infobox: {
            text: <Trans>This screen allows devs to customize the security of their apps.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9016934-design-core-app-info-security",
        },
        pageKey: "DESIGN_SECURITY",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, coreAppInfoBreadcrumb, { label: <Trans>Security</Trans> }],
    },
    {
        id: "/app/design/admin-tools/cache",
        title: (i18n: I18n) => i18n._(t`Cache`),
        infobox: {
            text: <Trans>App meta-data is cached on the api servers to improve performance. It updates automatically when the app configuration changes. In rare cimcumstances, refreshing the cache manually may fix ununsual errors.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9020639-design-admin-tools-cache",
        },
        pageKey: "DESIGN_ADMIN_APP_CACHE",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Cache</Trans> }],
    },
    {
        id: "/app/design/admin-tools/checkpoints",
        title: (i18n: I18n) => i18n._(t`Checkpoints`),
        infobox: {
            text: (
                <Trans>
                    Snapshots of your app's meta-data are created each time your app's configuration is updated via a deploy or a config import. You can restore your app's metadata to its previous state by restoring a checkpoint. Use with caution.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9029061-design-admin-tools-checkpoints",
        },
        pageKey: "DESIGN_ADMIN_CHECKPOINTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Checkpoints</Trans> }],
    },
    {
        id: "/app/design/admin-tools/configuration-data",
        title: (i18n: I18n) => i18n._(t`Configuration Data`),
        infobox: {
            text: <Trans>These utilities can be used to export or import the meta-data of your app. Use with caution.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9029115-design-admin-tools-configuration-data",
        },
        pageKey: "DESIGN_ADMIN_CONFIG_DATA",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Configuration Data</Trans> }],
    },
    {
        id: "/app/design/admin-tools/data-deletion",
        title: (i18n: I18n) => i18n._(t`Data Deletion (Use with Caution!)`),
        infobox: {
            text: <Trans>These utilities are normally used during development to bulk delete accounts. Use with caution.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9029157-design-admin-tools-data-deletion",
        },
        pageKey: "DESIGN_ADMIN_DATA_DELETION",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Data Deletion</Trans> }],
    },
    {
        id: "/app/design/admin-tools/deployment",
        title: (i18n: I18n) => i18n._(t`Deployment`),
        infobox: {
            text: (
                <Trans>
                    Deployment is the process used to push the configuration of one brainCloud app to another. It is normally used to propagate an app configuration through stages: development to stagings to production, etc. Use with caution.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9029127-design-admin-tools-deployment",
        },
        pageKey: "DESIGN_ADMIN_DEPLOYMENT",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Deployment</Trans> }],
    },
    {
        id: "/app/design/admin-tools/legacy-files-transfer",
        title: (i18n: I18n) => i18n._(t`Legacy Files Transfer`),
        infobox: {
            text: <Trans>These features are used to import and export legacy files. Use with caution.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9029144-design-admin-tools-legacy-files-transfer",
        },
        pageKey: "DESIGN_ADMIN_LEGACY_FILES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, adminToolsBreadcrumb, { label: <Trans>Legacy Files Transfer</Trans> }],
    },
    {
        id: "/app/design/authentication/email-authentication",
        title: (i18n: I18n) => i18n._(t`Email Authentication`),
        infobox: {
            text: (
                <Trans>
                    The system automates support for email-based user authentication. If enabled, the system will automatically send verification emails to new signups. The developer can choose to automatically reject users who have not verified
                    their email address. This page also allows the email templates to be customized and/or localized.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9036259-design-authentication-email-authentication",
        },
        pageKey: "DESIGN_AUTH_EMAIL",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, authenticationBreadcrumb, { label: <Trans>Email Authentication</Trans> }],
    },
    {
        id: "/app/design/authentication/external",
        title: (i18n: I18n) => i18n._(t`External Identity Authentication`),
        infobox: {
            text: <Trans>Allows the app to use external directories for authentication. Choose a name for the external identity type, and link it to a Cloud Code script to be run when authenticating.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9039890-design-authentication-external",
        },
        pageKey: "DESIGN_AUTH_EXTERNAL",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, authenticationBreadcrumb, { label: <Trans>External</Trans> }],
    },
    {
        id: "/app/design/authentication/passwords",
        title: (i18n: I18n) => i18n._(t`Authentication - Passwords`),
        infobox: {
            text: (
                <Trans>This feature allows your app to enforce minimum password standards. These settings are enforced when passwords are initially created and/or reset. Existing passwords in use are not affected by changes to these settings.</Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9040864-design-authentication-passwords",
        },
        pageKey: "DESIGN_AUTH_PASSWORDS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, authenticationBreadcrumb, { label: <Trans>Passwords</Trans> }],
    },
    {
        id: "/app/design/authentication/shared-accounts",
        title: (i18n: I18n) => i18n._(t`Shared Accounts Authentication`),
        infobox: {
            text: (
                <Trans>
                    Shared Accounts allow the profiles from one app (the parent) to be used as authentication for a second (child) app. This allows developers to share parent accounts across apps. Parent apps must declare that they "can be parents" -
                    and specify a logical name for their parent level (i.e. "Master", etc.). For more information, see Shared Accounts in the API docs.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9043072-design-authentication-shared-accounts",
        },
        pageKey: "DESIGN_AUTH_SHARED",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, authenticationBreadcrumb, { label: <Trans>Shared Accounts</Trans> }],
    },
    {
        id: "/app/design/cloud-code/api-explorer",
        title: (i18n: I18n) => i18n._(t`API Explorer`),
        infobox: {
            text: (
                <Trans>
                    The API explorer provides a quick way to experiment with the Client APIs, and even your own scripts. To begin, start by running the Authenticate method - and then access the rest of the API from there. API calls with a special
                    badge on the Run button are only callable from Cloud Code.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9044411-design-cloud-code-api-explorer",
        },
        pageKey: "DESIGN_API_EXPLORER",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>API Explorer</Trans> }],
    },
    {
        id: "/app/design/cloud-code/scripts",
        title: (i18n: I18n) => i18n._(t`Scripts`),
        infobox: {
            text: (
                <Trans>
                    Create and edit JavaScript-based Cloud Code scripts. Scripts can be organized into folders. You can import and export individual scripts, the entire library, or a subset of scripts by folder. Use the Action menu for bulk
                    operations.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9044580-design-cloud-code-scripts",
        },
        pageKey: "DESIGN_SCRIPT_FOLDERS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>Scripts</Trans> }],
    },
    {
        id: "/app/design/cloud-code/api-blocking",
        title: (i18n: I18n) => i18n._(t`API Blocking`),
        infobox: {
            text: <Trans>Allows developers to block specified client API calls - making these calls only available via cloud code scripts.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9057161-design-cloud-code-api-blocking",
        },
        pageKey: "DESIGN_API_BLOCKING",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>API Blocking</Trans> }],
    },
    {
        id: "/app/design/cloud-code/api-hooks",
        title: (i18n: I18n) => i18n._(t`API Hooks`),
        infobox: {
            text: <Trans>This screen allows you to configure custom Cloud Code scripts to run immediately before (pre) or after (post) the core Client API methods.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9062684-design-cloud-code-api-hooks",
        },
        pageKey: "DESIGN_API_HOOKS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>API Hooks</Trans> }],
    },
    {
        id: "/app/design/cloud-code/web-services",
        title: (i18n: I18n) => i18n._(t`Web Services Whitelist`),
        infobox: {
            text: <Trans>You must declare *any* external services that your cloud code scripts would like to use. Provide a short service code to identity the service, together with the root web url of the service endpoint.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9061797-design-cloud-code-web-services",
        },
        pageKey: "DESIGN_WEB_SERVICES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>Web Services</Trans> }],
    },
    {
        id: "/app/design/cloud-code/s2s-explorer",
        title: (i18n: I18n) => i18n._(t`S2S Explorer`),
        infobox: {
            text: <Trans>Used to test the S2S API and custom scripts from the perspective of an external server.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9061860-design-cloud-code-s2s-explorer",
        },
        pageKey: "DESIGN_S2S_EXPLORER",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>S2S Explorer</Trans> }],
    },
    {
        id: "/app/design/cloud-code/web-hooks",
        title: (i18n: I18n) => i18n._(t`WebHooks`),
        infobox: {
            text: <Trans>Configure webhooks, callable by external servers, that trigger specific Cloud Code scripts.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9062756-design-cloud-code-webhooks",
        },
        pageKey: "DESIGN_WEB_HOOKS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudCodeBreadcrumb, { label: <Trans>Web Hooks</Trans> }],
    },
    {
        id: "/app/design/cloud-data/custom-entities",
        title: (i18n: I18n) => i18n._(t`Custom Entity Collections`),
        infobox: {
            text: (
                <Trans>
                    More advanced than both Global and User Entities, Custom Entities provide high performance access to larger, richer datasets. Define custom indexes for faster look-ups. Enable the migration option to automatically migrate entities
                    between apps during deployment.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9073281-design-cloud-data-custom-entities",
        },
        pageKey: "DESIGN_CUSTOM_ENTITIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudDataBreadcrumb, { label: <Trans>Custom Entities</Trans> }],
    },
    {
        id: "/app/design/cloud-data/global-entities",
        title: (i18n: I18n) => i18n._(t`Global Entities`),
        infobox: {
            text: (
                <Trans>
                    Global Entities has been enhanced to support migration of un-owned system entities during deployments. This makes Global Entities very useful for managing reference data such as level data, etc. to mark a global entity type for
                    migration - add it to this list.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9073700-design-cloud-data-global-entities",
        },
        pageKey: "DESIGN_GLOBAL_ENTITIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudDataBreadcrumb, { label: <Trans>Global Entities</Trans> }],
    },
    {
        id: "/app/design/cloud-data/global-properties",
        title: (i18n: I18n) => i18n._(t`Global Properties`),
        infobox: {
            text: <Trans>Use Global Properties to configure the key parameters for your app that you want to be able to easily tweak server-side.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9074354-design-cloud-data-global-properties",
        },
        pageKey: "DESIGN_GLOBAL_PROPERTIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudDataBreadcrumb, { label: <Trans>Global Properties</Trans> }],
    },
    {
        id: "/app/design/cloud-data/global-statistics",
        title: (i18n: I18n) => i18n._(t`Global Statistics`),
        infobox: {
            text: <Trans>Global [or App] Statistics are similar to Player Stats, except that they are stored centrally across all of an app's users.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9074389-design-cloud-data-global-statistics",
        },
        pageKey: "DESIGN_GLOBAL_STATS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudDataBreadcrumb, { label: <Trans>Global Statistics</Trans> }],
    },
    {
        id: "/app/design/cloud-data/user-statistics",
        title: (i18n: I18n) => i18n._(t`User Statistics`),
        infobox: {
            text: (
                <Trans>
                    Player [or User] Statistics are used to track simple statistics that relate to a single user. Player stats are stored on the server in key + value pairs, and are accessed via a custom set of operations for maximum performance and
                    concurrency safety. Player Stats are also the key building blocks of the platform's Gamification features.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9074434-design-cloud-data-user-statistics",
        },
        pageKey: "DESIGN_USER_STATS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, cloudDataBreadcrumb, { label: <Trans>User Statistics</Trans> }],
    },
    {
        id: "/app/design/files/global-files",
        title: (i18n: I18n) => i18n._(t`Global Files`),
        infobox: {
            text: (
                <Trans>
                    The new Global Files service provides a flexible repository for organizing files that are globally available to all users of an app. To interact with these files from the Client Libraries or S2S, be sure to use the new Global
                    Files V3 API.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9095175-design-files-global-files",
        },
        pageKey: "DESIGN_GLOBAL_FILES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, filesBreadcrumb, { label: <Trans>Global Files</Trans> }],
    },
    {
        id: "/app/design/files/legacy-files",
        title: (i18n: I18n) => i18n._(t`Legacy Files`),
        infobox: {
            text: <Trans>Important: The Files service is a legacy service that is being replaced by the new Global Files service. It is recommended that all apps move to the new Global Files service instead.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9098541-design-files-legacy-files",
        },
        pageKey: "DESIGN_FILES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, filesBreadcrumb, { label: <Trans>Legacy Files</Trans> }],
    },
    {
        id: "/app/design/gamification/achievements",
        title: (i18n: I18n) => i18n._(t`Achievements`),
        infobox: {
            text: <Trans>Reward your players with Achievements. Achievements can be triggered directly from the client via API calls, or can be awarded automatically by the server by configuring Milestones and Level Up rewards.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9099509-design-gamification-achievements",
        },
        pageKey: "DESIGN_ACHIEVEMENTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, gamificationBreadcrumb, { label: <Trans>Achievements</Trans> }],
    },
    {
        id: "/app/design/gamification/milestones",
        title: (i18n: I18n) => i18n._(t`Milestones`),
        infobox: {
            text: (
                <Trans>
                    Milestones are used to automatically trigger player rewards, such as virtual currencies, achievements, etc. Milestones are configured to watch player statistics values, and trigger automatically when specified thresholds are
                    exceeded.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9099518-design-gamification-milestones",
        },
        pageKey: "DESIGN_MILESTONES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, gamificationBreadcrumb, { label: <Trans>Milestones</Trans> }],
    },
    {
        id: "/app/design/gamification/quests",
        title: (i18n: I18n) => i18n._(t`Quests`),
        infobox: {
            text: <Trans>Quests are used to represent more complex (compound) milestone conditions. A quest consists of multiple tasks (milestones) that must be complete before the reward is earned</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9103859-design-gamification-quests",
        },
        pageKey: "DESIGN_QUESTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, gamificationBreadcrumb, { label: <Trans>Quests</Trans> }],
    },
    {
        id: "/app/design/gamification/statistics-events",
        title: (i18n: I18n) => i18n._(t`Statistics Events`),
        infobox: {
            text: (
                <Trans>
                    Stats Events are a powerful design-level abstraction for manipulating Player Statistics. Think of them as macros, which when triggered, cause changes to multiple player stats to be triggered at once. Employing Stats Events in your
                    application allows your coders to work at a higher level, triggering stats events, while your designer is free to determine and tune the individual stats themselves.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9105572-design-gamification-statistic-events",
        },
        pageKey: "DESIGN_STATS_EVENTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, gamificationBreadcrumb, { label: <Trans>Statistics Events</Trans> }],
    },
    {
        id: "/app/design/gamification/xp-levels",
        title: (i18n: I18n) => i18n._(t`XP Levels`),
        infobox: {
            text: <Trans>Configure the XP Levels and thresholds for your experience - including rewards and achievements for Levelling Up. Also set the overall behaviour of the XP system.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9105678-design-gamification-xp-levels",
        },
        pageKey: "DESIGN_XP_LEVELS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, gamificationBreadcrumb, { label: <Trans>XP Levels</Trans> }],
    },
    {
        id: "/app/design/groups/group-types",
        title: (i18n: I18n) => i18n._(t`Group Types`),
        infobox: {
            text: (
                <Trans>
                    Groups are used to associate collections of users together - useful for representing Organizations in apps, and Clans and Guilds in games. An application can support multiple group types, which are configured here. Once the types
                    have been defined, Groups can then be created dynamically using the Groups API.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9111304-design-groups-group-types",
        },
        pageKey: "DESIGN_GROUP_TYPES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, groupsBreadcrumb, { label: <Trans>Group Types</Trans> }],
    },
    {
        id: "/app/design/integrations/blockchain",
        title: (i18n: I18n) => i18n._(t`Blockchain`),
        infobox: {
            text: <Trans>This section allows you to define blockchain configurations that your app can interact with.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9111515-design-integrations-blockchain",
        },
        pageKey: "DESIGN_MANAGE_BLOCKCHAINS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, integrationsBreadcrumb, { label: <Trans>Blockchain</Trans> }],
    },
    {
        id: "/app/design/integrations/manage-integrations",
        title: (i18n: I18n) => i18n._(t`Manage Integrations`),
        infobox: {
            text: (
                <Trans>
                    The platform has been designed from the very beginning to be a repository and activity stream for information about your app and its community of users. Integrating with third party systems is key to leveraging the value of this
                    data, and unlocking the potential of your apps. This section allows you to configure the third party systems that your apps interact with.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9111745-design-integrations-manage-integrations",
        },
        pageKey: "DESIGN_MANAGE_INTEGRATIONS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, integrationsBreadcrumb, { label: <Trans>Manage Integrations</Trans> }],
    },
    {
        id: "/app/design/integrations/peer-services",
        title: (i18n: I18n) => i18n._(t`Peer Services`),
        infobox: {
            text: <Trans>Peers are services that have been developed by 3rd parties, and are made available within the platform ecosystem.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9114455-design-integrations-peer-services",
        },
        pageKey: "DESIGN_MANAGE_PEER_SERVICES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, integrationsBreadcrumb, { label: <Trans>Peer Services</Trans> }],
    },
    {
        id: "/app/design/leaderboards/division-sets",
        title: (i18n: I18n) => i18n._(t`Division Sets`),
        infobox: {
            text: (
                <Trans>
                    Divisions break tournaments into smaller competitions for greater engagement. It is more fun to compete with one hundred players than one million! Division Sets define the rules used to create individual Division Instances.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9142748-design-leaderboards-division-sets",
        },
        pageKey: "DESIGN_DIVISION_SETS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, leaderboardsBreadcrumb, { label: <Trans>Division Sets</Trans> }],
    },
    {
        id: "/app/design/leaderboards/leaderboard-configs",
        title: (i18n: I18n) => i18n._(t`Leaderboard Configs`),
        infobox: {
            text: <Trans>Configure your leaderboards. All leaderboards can be accessed either socially (just friends) or globally (all entries). Leaderboards can be set to reset daily, weekly, monthly, yearly or never.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9143292-design-leaderboards-leaderboard-configs",
        },
        pageKey: "DESIGN_LEADERBOARD_CONFIG",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, leaderboardsBreadcrumb, { label: <Trans>Leaderboard Configs</Trans> }],
    },
    {
        id: "/app/design/leaderboards/pacers",
        title: (i18n: I18n) => i18n._(t`Pacers`),
        infobox: {
            text: (
                <Trans>
                    Pacers are pre-set leaderboard scores that allow players without friends to have someone to compete against. You define a global pacer list here, and then configure which pacers should appear in which leaderboards via the
                    individual leaderboard configuration.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9143915-design-leaderboards-pacers",
        },
        pageKey: "DESIGN_PACERS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, leaderboardsBreadcrumb, { label: <Trans>Pacers</Trans> }],
    },
    {
        id: "/app/design/leaderboards/tournament-templates",
        title: (i18n: I18n) => i18n._(t`Tournament Templates`),
        infobox: {
            text: (
                <Trans>
                    The Tournament System allows you to easily create global tournaments (based on leaderboards) that reward users prizes (via currency, xp, achievements and more) based on their tournament performance. The tournament rules (entry
                    fee, rank + prize rules, etc.) are defined here. To use, you must then attach the tournament to one of more leaderboards (from the leaderboard config screen).
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9143926-design-leaderboards-tournament-templates",
        },
        pageKey: "DESIGN_TOURNAMENT_CONFIG",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, leaderboardsBreadcrumb, { label: <Trans>Tournament Templates</Trans> }],
    },
    {
        id: "/app/design/marketplace/item-catalog",
        title: (i18n: I18n) => i18n._(t`Item Catalog`),
        infobox: {
            text: (
                <Trans>
                    Item Management is used to manage a collection of in-app item definitions that users can buy, sell or trade (using a virtual currency). This section allows you to manage the catalog of item definitions associated with your app.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9118346-design-marketplace-item-catalog",
        },
        pageKey: "DESIGN_ITEMMGMT_CATALOG",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Item Catalog</Trans> }],
    },
    {
        id: "/app/design/marketplace/products",
        title: (i18n: I18n) => i18n._(t`Products`),
        infobox: {
            text: (
                <Trans>
                    Products are items purchasable via IAP (In-App Purchases). Create a separate product for each item (e.g. bundle of currency) that you intend to sell. Every product can (and should) be pre-configured with multiple price points. The
                    active price point can be changed using Promotions.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9120848-design-marketplace-products",
        },
        pageKey: "DESIGN_PRODUCTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Products</Trans> }],
    },
    {
        id: "/app/design/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`Redemption Codes`),
        infobox: {
            text: (
                <Trans>
                    Reward users with Redemption Code campaigns. Two types are supported: Personal codes - a series of unique codes that can be redeemed once apiece. Shared code - A time-limited single code for all users, but each user can only
                    redeem it once.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9125989-design-marketplace-redemption-codes",
        },
        pageKey: "DESIGN_REDEMPTION_CODES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Redemption Codes</Trans> }],
    },
    {
        id: "/app/design/marketplace/virtual-currencies",
        title: (i18n: I18n) => i18n._(t`Virtual Currencies`),
        infobox: {
            text: <Trans>Configure the virtual currencies for your app here. Coins, bars, hearts, rupees - we accept all denominations!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9131933-design-marketplace-virtual-currencies",
        },
        pageKey: "DESIGN_VIRTUAL_CURRENCIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Virtual Currencies</Trans> }],
    },
    {
        id: "/app/design/messaging/chat",
        title: (i18n: I18n) => i18n._(t`Configure Chat`),
        infobox: {
            text: (
                <Trans>
                    Chat allows end-users to communicate with each other in realtime. All communication is directed to channels, and pushed to participating users in realtime via RTT. A rolling history of chat messages is maintained per channel.
                    These settings configure the maximum sizes of chat messages and the size of history to be maintained. Pro-tip: set limits as low as possible to minimize usage costs. This feature requires RTT, which can be enabled via Advanced
                    Settings. Profanity filtering requires WebPurify integration, which can be configured on the Manage Integrations page.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9146805-design-messaging-chat",
        },
        pageKey: "DESIGN_MESSAGING_CHAT",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, messagingBreadcrumb, { label: <Trans>Chat</Trans> }],
    },
    {
        id: "/app/design/messaging/chat-channels",
        title: (i18n: I18n) => i18n._(t`Chat Channels`),
        infobox: {
            text: <Trans>Chat supports both global channels and group channels. Global channels are available to all members of an app, and must be configured here. Group channels are automatically provisioned for each group.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9146912-design-messaging-chat-channels",
        },
        pageKey: "DESIGN_MESSAGING_CHAT_GLOBAL",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, messagingBreadcrumb, { label: <Trans>Chat Channels</Trans> }],
    },
    {
        id: "/app/design/messaging/messaging",
        title: (i18n: I18n) => i18n._(t`Configure Messaging`),
        infobox: {
            text: (
                <Trans>
                    Messaging is used for sending directed messages between end-users. The paradigm is similar to email, but stays within your app. Online users are informed of new messages via RTT. A rolling history of messages is maintained per
                    user. These settings configure the maximum sizes of chat messages and the size of history to be maintained. Pro-tip: set limits as low as possible to minimize usage costs.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9146977-design-messaging-messaging",
        },
        pageKey: "DESIGN_MESSAGING_MESSAGES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, messagingBreadcrumb, { label: <Trans>Messaging</Trans> }],
    },
    {
        id: "/app/design/messaging/presence",
        title: (i18n: I18n) => i18n._(t`Presence Config`),
        infobox: {
            text: <Trans>Presence uses RTT to detect and communicate the online status</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9147028-design-messaging-presence",
        },
        pageKey: "DESIGN_MESSAGING_PRESENCE",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, messagingBreadcrumb, { label: <Trans>Presence</Trans> }],
    },
    {
        id: "/app/design/multiplayer/lobbies",
        title: (i18n: I18n) => i18n._(t`Configure Lobbies`),
        infobox: {
            text: (
                <Trans>
                    Lobbies are used to gather participants together in preparation for shared multi-user experiences. Lobby settings determine the composition of a lobby, the rules for lobby progression, and the configuration data to send to the
                    experience at launch. Requires RTT, which can be enabled via Advanced Settings.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9147771-design-multiplayer-lobbies",
        },
        pageKey: "DESIGN_LOBBIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, multiplayerBreadcrumb, { label: <Trans>Lobbies</Trans> }],
    },
    {
        id: "/app/design/multiplayer/matchmaking",
        title: (i18n: I18n) => i18n._(t`Matchmaking`),
        infobox: {
            text: (
                <Trans>
                    This page is used to configure the behaviour for Offline Matchmaking. The default Matchmaking algorithm is tuned via the specified parameters - plus you can optionally provide a Cloud Code script to be used to filter potential
                    candidates.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9147875-design-multiplayer-matchmaking",
        },
        pageKey: "DESIGN_MATCHMAKING",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, multiplayerBreadcrumb, { label: <Trans>Matchmaking</Trans> }],
    },
    {
        id: "/app/design/notifications/settings",
        title: (i18n: I18n) => i18n._(t`Notification Settings`),
        infobox: {
            text: <Trans>Configure your app for push notifications.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9147923-design-notifications-settings",
        },
        pageKey: "DESIGN_PUSH_SETTINGS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, notificationsBreadcrumb, { label: <Trans>Settings</Trans> }],
    },
    {
        id: "/app/design/notifications/templates",
        title: (i18n: I18n) => i18n._(t`Notification Templates`),
        infobox: {
            text: <Trans>Define push notification templates to be used to communicate with your user community.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9147932-design-notifications-templates",
        },
        pageKey: "DESIGN_TEMPLATES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, notificationsBreadcrumb, { label: <Trans>Templates</Trans> }],
    },
    {
        id: "/app/design/promotions/automated",
        title: (i18n: I18n) => i18n._(t`Automated Promotions`),
        infobox: {
            text: <Trans>Auto Promotions are automatically triggered if a user (player) enters a defined Segment. Segments and Auto Promotions are refreshed daily for activation.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9151617-design-promotions-automated",
        },
        pageKey: "DESIGN_AUTO_PROMOTIONS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, promotionsBreadcrumb, { label: <Trans>Automated</Trans> }],
    },
    {
        id: "/app/design/promotions/scheduled",
        title: (i18n: I18n) => i18n._(t`Scheduled Promotions`),
        infobox: {
            text: <Trans>Scheduled Promotions are used to automatically set sale pricing of your products for particular calendar date range.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9151622-design-promotions-scheduled",
        },
        pageKey: "DESIGN_SCHEDULED_PROMOTIONS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, promotionsBreadcrumb, { label: <Trans>Scheduled</Trans> }],
    },
    {
        id: "/app/design/segmentation/segments",
        title: (i18n: I18n) => i18n._(t`Segments`),
        infobox: {
            text: (
                <Trans>
                    Segments are used to identify subsets of your user (player) population. Segments are useful for filtering Push Notifications and Scheduled Promotions, as well as for triggering Auto Promotions. Segments refresh nightly and each
                    time a user logs into the app. A newly created segment can take up to 24 hours to fully populate.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9153822-design-segmentation-segments",
        },
        pageKey: "DESIGN_SEGMENTS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, segmentationBreadcrumb, { label: <Trans>Segments</Trans> }],
    },
    {
        id: "/app/design/servers/my-servers",
        title: (i18n: I18n) => i18n._(t`My Servers`),
        infobox: {
            text: (
                <Trans>
                    This section is used to configure custom servers that will be <b>hosted</b> by and/or closely interact with your app via the <b>S2S API. Custom Servers</b> are simple external servers that require S2S access. <b>Relay Servers</b>{" "}
                    are hosted specifically for your app and launched from Lobbies. <b>Room Servers</b> are custom servers hosted for your app and launched from Lobbies. You provide the docker image with the server software to run.{" "}
                    <b>Room Server Managers</b> are external servers that can act as a Room Server Manager for Lobby Services.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9153860-design-servers-my-servers",
        },
        pageKey: "DESIGN_S2S_CONFIG",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, serversBreadcrumb, { label: <Trans>My Servers</Trans> }],
    },
    {
        id: "/app/design/super/screens",
        title: (i18n: I18n) => i18n._(t`Super Screens`),
        infobox: {
            text: <Trans>Used to enable/disable select screens globally across the system. You can also override screens on an app-by-app basis.</Trans>,
        },
        pageKey: "DESIGN_BRAINCLOUD_SCREENS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, superBreadcrumb, { label: <Trans>Screens</Trans> }],
    },
    {
        id: "/app/design/super/property-overrides",
        title: (i18n: I18n) => i18n._(t`System Property Overrides`),
        infobox: {
            text: <Trans>This screen allows Super-Users to override the default System Property value for the selected app.</Trans>,
        },
        pageKey: "DESIGN_BRAINCLOUD_PROPERTIES",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, superBreadcrumb, { label: <Trans>Property Overrides</Trans> }],
    },
    {
        id: "/app/design/portal/user-summary-configuration",
        title: (i18n: I18n) => i18n._(t`User Summary Configuration`),
        infobox: {
            text: <Trans>Customize the information you wish to showcase in the User Summary.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9151424-design-portal-user-summary-configuration",
        },
        pageKey: "DESIGN_PORTAL_USERSUMMARY",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, portalBreadcrumb, { label: <Trans>User Summary Configuration</Trans> }],
    },
    {
        id: "/app/design/portal/user-browser-settings",
        title: (i18n: I18n) => i18n._(t`User Browser Settings`),
        infobox: {
            text: <Trans>Define the User ID's that appear in the search type dropdown and how they are labeled. Pro Tip: To remove Profile ID as the Default choice, please choose a different ID to be the default.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9149901-design-portal-user-browser-settings",
        },
        pageKey: "DESIGN_PORTAL_USERBROWSER",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, portalBreadcrumb, { label: <Trans>User Browser Settings</Trans> }],
    },
    {
        id: "/app/global/global-data/custom-entities",
        title: (i18n: I18n) => i18n._(t`Custom Entities`),
        infobox: {
            text: <Trans>Manage the application's Custom Entities. Click on the name of the collection to manage.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9155991-global-global-data-custom-entities",
        },
        pageKey: "MONITOR_CUSTOM_ENTITIES",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, globalDataBreadcrumb, { label: <Trans>Custom Entities</Trans> }],
    },
    {
        id: "/app/global/global-data/global-entities",
        title: (i18n: I18n) => i18n._(t`Global Entities`),
        infobox: {
            text: (
                <Trans>
                    Displays a list of the apps Global Entities, filterable by type. Global Entities are [by default] accessible to all users. Organize entities by type, and specify string-based index ids for fast lookups. Caution: If a Global Entity
                    is owned by a user, it will automatically be removed when that user is deleted.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9160662-global-global-data-global-entities",
        },
        pageKey: "MONITOR_GLOBAL_ENTITIES",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, globalDataBreadcrumb, { label: <Trans>Global Entities</Trans> }],
    },
    {
        id: "/app/global/global-data/global-statistics",
        title: (i18n: I18n) => i18n._(t`Global Statistics`),
        infobox: {
            text: <Trans>View (and potentially edit) the current values of global statistics. Be careful - this data is live!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9166472-global-global-data-global-statistics",
        },
        pageKey: "MONITOR_GLOBAL_STATS",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, globalDataBreadcrumb, { label: <Trans>Global Statistics</Trans> }],
    },
    {
        id: "/app/global/leaderboards/leaderboards",
        title: (i18n: I18n) => i18n._(t`Leaderboards`),
        infobox: {
            text: <Trans>View and manage your game's leaderboards during development and production. Quickly view players or delete entries using the Action menu.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9168724-global-leaderboards-leaderboards",
        },
        pageKey: "MONITOR_LEADERBOARDS",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, leaderboardsBreadcrumb, { label: <Trans>Leaderboards</Trans> }],
    },
    {
        id: "/app/global/logs/job-queue",
        title: (i18n: I18n) => i18n._(t`Job Queue Logs`),
        infobox: {
            text: <Trans>View the jobs currently enqueued.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9172489-global-logs-job-queue-logs",
        },
        pageKey: "MONITOR_JOB_QUEUE",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, logsBreadcrumb, { label: <Trans>Job Queue</Trans> }],
    },
    {
        id: "/app/global/logs/recent-errors",
        title: (i18n: I18n) => i18n._(t`Recent Error Logs`),
        infobox: {
            text: <Trans>Displays a log of errors and warnings recorded on the server for this application.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9174331-global-logs-recent-error-logs",
        },
        pageKey: "MONITOR_GLOBAL_LOGS",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, logsBreadcrumb, { label: <Trans>Recent Errors</Trans> }],
    },
    {
        id: "/app/global/logs/server-logs",
        title: (i18n: I18n) => i18n._(t`Server Logs`),
        infobox: {
            text: <Trans>View the most recent Server-to-Server (S2S) logs. Sessions IDs that are in grey italics were generated via the session-less protocol.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9174559-global-logs-server-logs",
        },
        pageKey: "MONITOR_SERVER_REQUESTS",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, logsBreadcrumb, { label: <Trans>Server Logs</Trans> }],
    },
    {
        id: "/app/global/logs/unreadable-requests",
        title: (i18n: I18n) => i18n._(t`Unreadable Request Logs`),
        infobox: {
            text: (
                <Trans>
                    These are requests sent to the server that cannot be processed. The most common cause for these errors are networking errors (we didn't receive the full request), bad serialization of JSON objects or security issues (bad packet
                    id, secret, etc.).
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9174678-global-logs-unreadable-request-logs",
        },
        pageKey: "MONITOR_UNREADABLE_REQUESTS",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, logsBreadcrumb, { label: <Trans>Unreadable Requests</Trans> }],
    },
    {
        id: "/app/global/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`Redemption Codes Monitoring`),
        infobox: {
            text: <Trans>View the status of individual redemption codes. You may also reset the status of the code. To reset the status of all codes, select "View Config" button.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9176091-global-marketplace-redemption-codes-monitoring",
        },
        pageKey: "MONITOR_REDEMPTION_CODES",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Redemption Codes</Trans> }],
    },
    {
        id: "/app/global/marketplace/transactions",
        title: (i18n: I18n) => i18n._(t`Global Transactions`),
        infobox: {
            text: <Trans>View and review In-App Purchase transactions. </Trans>,
            learnMore: null,
        },
        pageKey: "MONITOR_GLOBAL_TRANSACTIONS",
        breadcrumbs: [appDashboardBreadcrumb, designBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Global Transactions</Trans> }],
    },
    {
        id: "/app/global/marketplace/disputes",
        title: (i18n: I18n) => i18n._(t`Disputes`),
        infobox: {
            text: <Trans>TODO</Trans>,
            learnMore: null,
        },
        pageKey: "MONITOR_DISPUTES",
        breadcrumbs: [appDashboardBreadcrumb, globalBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Disputes</Trans> }],
    },
    {
        id: "/app/groups/groups",
        title: (i18n: I18n) => i18n._(t`Groups`),
        infobox: {
            text: (
                <Trans>
                    Displays all the Groups that have been created for your application. You can filter the list by group type, or search for the name of a particular group. Choose View/Edit from the Action menu to view members and/or the core data
                    of the group. Choose View Group Entities to see a list of entities associated with this group.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9184505-groups-groups",
        },
        pageKey: "MONITOR_GROUP_GROUPS",
        breadcrumbs: [appDashboardBreadcrumb, groupsBreadcrumb, { label: <Trans>Groups</Trans> }],
    },
    {
        id: "/app/groups/group-entities",
        title: (i18n: I18n) => i18n._(t`Group Entities`),
        infobox: {
            text: <Trans>Displays all the Group Entities for your application. To easily filter for all the entities of a particular group, locate the group in the Groups page above, and then choose View Group Entities from the Action menu.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9185082-groups-groups-entities",
        },
        pageKey: "MONITOR_GROUP_ENTITIES",
        breadcrumbs: [appDashboardBreadcrumb, groupsBreadcrumb, { label: <Trans>Groups Entities</Trans> }],
    },
    {
        id: "/app/reports/analytics/core",
        title: (i18n: I18n) => i18n._(t`App Analytics`),
        infobox: {
            text: <Trans>Dashboard - provides a snapshot of recent activity in your application. These statistics update every ~10 minutes.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9185331-reports-analytics",
        },
        pageKey: "REPORT_DASH_ANALYTICS",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, { label: <Trans>Analytics</Trans> }],
    },
    {
        id: "/app/reports/analytics/product",
        title: (i18n: I18n) => i18n._(t`Product Analytics`),
        infobox: {
            text: <Trans>Compare and investigate product sales performance.</Trans>,
        },
        pageKey: "REPORT_ANALYTICS_TRANSACTIONS",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, { label: <Trans>Product</Trans> }],
    },
    {
        id: "/app/reports/usage/profile",
        title: (i18n: I18n) => i18n._(t`App Profile`),
        infobox: {
            text: <Trans>Developers can review the historical usage of their app from this page.</Trans>,
        },
        pageKey: "REPORT_APP_METRICS",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, usageBreadcrumb, { label: <Trans>Profile</Trans> }],
    },
    {
        id: "/app/reports/usage/api-usage",
        title: (i18n: I18n) => i18n._(t`App API Usage`),
        infobox: {
            text: (
                <Trans>
                    These analytics are useful for understanding the API usage of your application, and how that is likely to affect billing. The per API stats at the bottom of the page give an understanding of the performance your app is seeing per
                    API call. These statistics update every ~10 minutes.
                </Trans>
            ),
        },
        pageKey: "REPORT_API_USAGE",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, usageBreadcrumb, { label: <Trans>API Usage</Trans> }],
    },
    {
        id: "/app/reports/usage/api-errors",
        title: (i18n: I18n) => i18n._(t`App API Errors`),
        infobox: {
            text: <Trans>This page shows a breakdown of error statistics per API call. Clicking on an API will bring you to a filtered list of recent errors. These statistics update every ~10 minutes.</Trans>,
        },
        pageKey: "REPORT_ERROR_ANALYTICS",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, usageBreadcrumb, { label: <Trans>API Errors</Trans> }],
    },
    {
        id: "/app/reports/usage/data-usage",
        title: (i18n: I18n) => i18n._(t`App Data Usage`),
        pageKey: "REPORT_DATA_USAGE",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, usageBreadcrumb, { label: <Trans>Data Usage</Trans> }],
    },
    {
        id: "/app/reports/usage/script-usage",
        title: (i18n: I18n) => i18n._(t`App Script Usage`),
        infobox: {
            text: <Trans>This page is used to assess the overall performance and API usage of cloud code scripts. It is very useful when tracking optimization progress. Click on a script for more information.</Trans>,
        },
        pageKey: "REPORT_SCRIPT_USAGE",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, usageBreadcrumb, { label: <Trans>Script Usage</Trans> }],
    },
    {
        id: "/app/reports/exports/export-users",
        title: (i18n: I18n) => i18n._(t`Export App Users`),
        infobox: {
            text: <Trans>Download your raw user data to do custom analytics, import into mailing lists, etc.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9185381-reports-exports-export-users",
        },
        pageKey: "REPORT_EXPORT_USERS",
        breadcrumbs: [appDashboardBreadcrumb, reportsBreadcrumb, exportsBreadcrumb, { label: <Trans>Export Users</Trans> }],
    },
];

export const userScreens: ScreenData[] = [
    {
        id: "/user/user-browser",
        title: (i18n: I18n) => i18n._(t`User Browser`),
        infobox: {
            text: (
                <Trans>
                    Search for end-user accounts to view. Recently Active shows users who have most recently logged into the app. New Users are new account registrations. Recently Viewed are accounts that you have viewed in this browser. Flagged
                    accounts are users that are flagged in the app. Browse iterates through all accounts by name - and is most useful for apps with a smaller number of accounts.
                </Trans>
            ),
            learnMore: null,
        },
        search: {
            title: (i18n: I18n) => i18n._(t`USER > Browser`),
        },
        pageKey: "MONITOR_USER_BROWSER",
        breadcrumbs: [{ label: <Trans>User</Trans> }],
    },
    {
        id: "/user/summary/user-summary",
        title: (i18n: I18n) => i18n._(t`User Summary`),
        infobox: {
            text: <Trans>Key information about the end user is summarized here. You can even flag users (and add notes) to make them easier to look up later.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9185441-user-summary-user-summary",
        },
        pageKey: "MONITOR_USER_SUMMARY",
        breadcrumbs: [userDashboardBreadcrumb, summaryBreadcrumb, { label: <Trans>User Summary</Trans> }],
    },
    {
        id: "/user/summary/user-activity",
        title: (i18n: I18n) => i18n._(t`User Activity`),
        infobox: {
            text: <Trans>Summarizes the most recent interactions of the user with the app.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9202123-user-summary-user-activity",
        },
        pageKey: "MONITOR_USER_ACTIVITY",
        breadcrumbs: [userDashboardBreadcrumb, summaryBreadcrumb, { label: <Trans>User Activity</Trans> }],
    },
    {
        id: "/user/summary/logs",
        title: (i18n: I18n) => i18n._(t`User Logs`),
        infobox: {
            text: <Trans>View any recent client requests and responses. Useful for debugging during development!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9187973-user-summary-logs",
        },
        pageKey: "MONITOR_USER_LOGS",
        breadcrumbs: [userDashboardBreadcrumb, summaryBreadcrumb, { label: <Trans>Logs</Trans> }],
    },
    {
        id: "/user/summary/recent-errors",
        title: (i18n: I18n) => i18n._(t`User Recent Error Logs`),
        infobox: {
            text: <Trans>Displays a log of errors and warnings recorded on the server for this application.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9201099-user-summary-recent-errors",
        },
        pageKey: "MONITOR_GLOBAL_LOGS",
        breadcrumbs: [userDashboardBreadcrumb, summaryBreadcrumb, { label: <Trans>Recent Errors</Trans> }],
    },
    {
        id: "/user/summary/unreadable-requests",
        title: (i18n: I18n) => i18n._(t`User Unreadable Request Logs`),
        infobox: {
            text: (
                <Trans>
                    These are requests sent to the server that cannot be processed. The most common cause for these errors are networking errors (we didn't receive the full request), bad serialization of JSON objects or security issues (bad packet
                    id, secret, etc.).
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9201211-user-summary-unreadable-request",
        },
        pageKey: "MONITOR_UNREADABLE_REQUESTS",
        breadcrumbs: [userDashboardBreadcrumb, summaryBreadcrumb, { label: <Trans>Unreadable Requests</Trans> }],
    },
    {
        id: "/user/data/attributes",
        title: (i18n: I18n) => i18n._(t`User Attributes`),
        infobox: {
            text: <Trans>Attributes are tags (key+value pairs) that can be assigned to a user (manually or programmatically) - primarily to be used in defining and filtering segments for Promotions.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9202152-user-data-attributes",
        },
        pageKey: "MONITOR_USER_ATTRIBUTES",
        breadcrumbs: [userDashboardBreadcrumb, dataBreadcrumb, { label: <Trans>Attributes</Trans> }],
    },
    {
        id: "/user/data/custom-entities",
        title: (i18n: I18n) => i18n._(t`User Custom Entities`),
        infobox: {
            text: <Trans>Manage the Custom Entities that are owned by the selected user. Click on a collection to view / edit individual entities.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9204376-user-data-custom-entities",
        },
        pageKey: "MONITOR_USER_CUSTOM_ENTITIES",
        breadcrumbs: [userDashboardBreadcrumb, dataBreadcrumb, { label: <Trans>Custom Entities</Trans> }],
    },
    {
        id: "/user/data/statistics",
        title: (i18n: I18n) => i18n._(t`User Statistics`),
        infobox: {
            text: <Trans>View (and potentially edit) the current values of the user's statistics. Be careful - this data is live!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205484-user-data-statistics",
        },
        pageKey: "MONITOR_USER_STATS",
        breadcrumbs: [userDashboardBreadcrumb, dataBreadcrumb, { label: <Trans>Statistics</Trans> }],
    },
    {
        id: "/user/data/user-entities",
        title: (i18n: I18n) => i18n._(t`User Entities`),
        infobox: {
            text: <Trans>View, edit and/or delete the entities associated with this user.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205574-user-data-user-entities",
        },
        pageKey: "MONITOR_USER_ENTITIES",
        breadcrumbs: [userDashboardBreadcrumb, dataBreadcrumb, { label: <Trans>User Entities</Trans> }],
    },
    {
        id: "/user/data/user-files",
        title: (i18n: I18n) => i18n._(t`User Files`),
        infobox: {
            text: <Trans>For viewing the files uploaded by the current user.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205719-user-data-user-files",
        },
        pageKey: "MONITOR_USER_FILES",
        breadcrumbs: [userDashboardBreadcrumb, dataBreadcrumb, { label: <Trans>User Files</Trans> }],
    },
    {
        id: "/user/gamification/achievements",
        title: (i18n: I18n) => i18n._(t`User Achievements`),
        infobox: {
            text: <Trans>Discover what achievements the user has earned.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205790-user-gamification-achievements",
        },
        pageKey: "MONITOR_ACHIEVEMENTS",
        breadcrumbs: [userDashboardBreadcrumb, gamificationBreadcrumb, { label: <Trans>Achievements</Trans> }],
    },
    {
        id: "/user/gamification/milestones-and-quests",
        title: (i18n: I18n) => i18n._(t`User Milestones & Quests`),
        infobox: {
            text: <Trans>View the status of the user's milestones and quests.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205797-user-gamification-milestones-quests",
        },
        pageKey: "MONITOR_MILESTONES",
        breadcrumbs: [userDashboardBreadcrumb, gamificationBreadcrumb, { label: <Trans>Milestones & Quests</Trans> }],
    },
    {
        id: "/user/marketplace/virtual-currency",
        title: (i18n: I18n) => i18n._(t`User Virtual Currency`),
        infobox: {
            text: <Trans>View and edit the user's virtual currency balances. Select the Edit option to add/remove currency to their balance. Useful in support situations!</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205817-user-marketplace-virtual-currency",
        },
        pageKey: "MONITOR_VIRTUAL_CURRENCY",
        breadcrumbs: [userDashboardBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Virtual Currency</Trans> }],
    },
    {
        id: "/user/marketplace/transactions",
        title: (i18n: I18n) => i18n._(t`User Transactions`),
        infobox: {
            text: <Trans>Displays the user's in-app purchase transactions.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9205836-user-marketplace-transactions",
        },
        pageKey: "MONITOR_USER_PURCHASES",
        breadcrumbs: [userDashboardBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Transactions</Trans> }],
    },
    {
        id: "/user/marketplace/redemption-codes",
        title: (i18n: I18n) => i18n._(t`User Redemption Codes`),
        infobox: {
            text: <Trans>View the list of codes that have been redeemed by this user.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9206561-user-marketplace-redemption-codes",
        },
        pageKey: "MONITOR_RECEPTIONS_CODES",
        breadcrumbs: [userDashboardBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Redemption Codes</Trans> }],
    },
    {
        id: "/user/marketplace/pricing",
        title: (i18n: I18n) => i18n._(t`User Personalized Pricing`),
        infobox: {
            text: (
                <Trans>
                    The Pricing view is useful for understanding and debugging a user's view of product pricing, and how it is affected by Promotions. This screen shows what segments a user is currently in, what promotions currently apply to them
                    (both schedules and auto), and the resulting prices of all available products.
                </Trans>
            ),
            learnMore: "https://help.getbraincloud.com/en/articles/9206600-user-marketplace-personalized-pricing",
        },
        pageKey: "MONITOR_PRICING",
        breadcrumbs: [userDashboardBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Personalized Pricing</Trans> }],
    },
    {
        id: "/user/marketplace/inventory",
        title: (i18n: I18n) => i18n._(t`User Inventory`),
        infobox: {
            text: <Trans>View the items owned by this user. Supports deleting of items (mostly for testing purposes). Use the API Explorer to edit individual items.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9206613-user-marketplace-inventory",
        },
        pageKey: "MONITOR_VIRTUAL_ITEMS",
        breadcrumbs: [userDashboardBreadcrumb, marketplaceBreadcrumb, { label: <Trans>Inventory</Trans> }],
    },
    {
        id: "/user/multiplayer/turn-by-turn-mp",
        title: (i18n: I18n) => i18n._(t`User Turn Based Multiplayer`),
        infobox: {
            text: <Trans>View the status of any turn-based matches that the user has in progress.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9213329-user-multiplayer-turn-by-turn-mp",
        },
        pageKey: "MONITOR_TURN_BY_TURN",
        breadcrumbs: [userDashboardBreadcrumb, multiplayerBreadcrumb, { label: <Trans>Turn-By-Turn MP</Trans> }],
    },
    {
        id: "/user/multiplayer/one-way-mp",
        title: (i18n: I18n) => i18n._(t`User One Way Multiplayer`),
        infobox: {
            text: <Trans>View the status of recent one-way multiplayer matches involving this user.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9213371-user-multiplayer-one-way-mp",
        },
        pageKey: "MONITOR_ONEWAY",
        breadcrumbs: [userDashboardBreadcrumb, multiplayerBreadcrumb, { label: <Trans>One-Way MP</Trans> }],
    },
    {
        id: "/user/social/friends",
        title: (i18n: I18n) => i18n._(t`User Friends`),
        infobox: {
            text: <Trans>Displays a list of this user's friends. You can filter by friend source, and click on a profile id to jump to that user.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9213418-user-social-friends",
        },
        pageKey: "MONITOR_USER_FRIENDS",
        breadcrumbs: [userDashboardBreadcrumb, socialBreadcrumb, { label: <Trans>Friends</Trans> }],
    },
    {
        id: "/user/social/groups",
        title: (i18n: I18n) => i18n._(t`User Groups`),
        infobox: {
            text: <Trans>Displays the list of groups that this user is a member of. Click on the Group id to view more information about that group.</Trans>,
            learnMore: "https://help.getbraincloud.com/en/articles/9213383-user-social-groups",
        },
        pageKey: "MONITOR_USER_GROUPS",
        breadcrumbs: [userDashboardBreadcrumb, socialBreadcrumb, { label: <Trans>Groups</Trans> }],
    },
];

export const allScreens: ScreenData[] = [...superScreens, ...teamScreens, ...appScreens, ...userScreens];

import AppStatus from "types/enums/AppStatus";
import PublishState from "types/enums/PublishState";
import RunState from "types/enums/RunState";
import BillingPlan, { processBillingPlan } from "./BillingPlan";

export default interface AppInfo {
    // Common.
    createdAt?: Date;
    updatedAt?: Date;

    appId: string;
    appName: string;

    companyId: string;
    companyName: string;

    accountNumber?: string | null;

    imageUrl?: string | null;
    absoluteImageUrl?: string | null;

    secret?: string | null;

    // Boolean Flags.
    isLive?: boolean;
    isDeleted?: boolean;
    isHardDeleted?: boolean;
    isEnabled?: boolean;
    isUserEnabled?: boolean;
    isGamificationEnabled?: boolean;
    isBillingEnabled?: boolean;

    hasGameAccess?: boolean;

    //Libraries
    majorLibrary?: string;
    majorLibVersion?: string;

    // Publish State.
    publishState?: PublishState;

    // Run State.
    run_state?: RunState;

    // Run Limit.
    run_limit?: number;

    // App Status.
    app_status?: AppStatus;

    /*** Statiatics and counts.  ***/

    // Counts.
    users_new?: number;
    users_total?: number;
    users_dau?: number;
    users_api_per_dau?: number;

    sessions_total?: number;
    sessions_per_dau?: number;

    // Billing.
    billing_total?: number;
    billing_discount?: number;
    billing_plan_name?: string;
    billing_plan?: BillingPlan;

    // API Usage.
    apiusage_total?: number;
    apiusage_notifications?: number;
    apiusage_web_daily?: number;
    apiusage_s2s_daily?: number;
    apiusage_webhook_daily?: number;
    apiusage_offboardevent_daily?: number;
    apiusage_scheduledscripts_daily?: number;

    // Purchases / Revenue.
    purchases_count?: number;
    purchases_total?: number;
    purchases_itunes?: number;
    purchases_googleplay?: number;
    purchases_other?: number;
}

export interface PurchasesTotalRevenue {
    sumPurchaseCount: number;
    sumRefPrice: number;
    sumRefPriceMissing: number;
    aggregatedPurchaseCount: number;
    aggregatedRevenue: number;
}

export const processPurchasesTotalRevenue = (data: any): PurchasesTotalRevenue => {
    return {
        sumPurchaseCount: data.sumPurchaseCount,
        sumRefPrice: data.sumRefPrice,
        sumRefPriceMissing: data.sumRefPriceMissing,
        aggregatedPurchaseCount: data.aggregatedGameCountsPurchaseCount,
        aggregatedRevenue: data.aggregatedGameCountsRevenue,
    };
};

export const processAppInfo = (data: any): AppInfo => {
    const result: AppInfo = {
        createdAt: data.createdAt != null ? new Date(data.createdAt) : undefined,
        updatedAt: data.updatedAt != null ? new Date(data.updatedAt) : undefined,

        appId: data.gameId,
        appName: data.gameName,

        companyId: data.companyId,
        companyName: data.companyName,

        accountNumber: data.accountNumber,

        imageUrl: data.imageUrl,
        absoluteImageUrl: data.absoluteImageUrl,

        secret: data.secret,

        publishState: data.publishState,

        majorLibrary: data.majorLibrary,
        majorLibVersion: data.majorLibVersion,

        isLive: data.isLive != null ? data.isLive : false,
        isDeleted: data.isDeleted != null ? data.isDeleted : false,
        isHardDeleted: data.isHardDeleted != null ? data.isHardDeleted : false,
        isEnabled: data.isEnabled != null ? data.isEnabled : false,
        isUserEnabled: data.isUserEnabled != null ? data.isUserEnabled : false,
        isGamificationEnabled: data.isGamificationEnabled != null ? data.isGamificationEnabled : false,
        isBillingEnabled: data.isBillingEnabled != null ? data.isBillingEnabled : false,
        hasGameAccess: data.hasGameAccess != null ? data.hasGameAccess : false,

        run_state: data.runState,

        run_limit: data.runLimit,

        users_new: data.accountCreationCount != null ? data.accountCreationCount : 0,
        users_total: data.playerCount != null ? data.playerCount : 0,
        users_dau: data.userCount != null ? data.userCount : 0,
        users_api_per_dau: 0,

        sessions_total: data.sessionCount != null ? data.sessionCount : 0,
        sessions_per_dau: 0,

        billing_total: data.billingTotalBilledToCustomer != null ? data.billingTotalBilledToCustomer : 0,
        billing_plan_name: data.planName != null ? data.planName : null,
        billing_plan: data.plan != null ? processBillingPlan(data.plan) : undefined,
        billing_discount: data.discountCount != null ? data.discountCount : 0,

        apiusage_total: 0,
        apiusage_notifications: data.notificationCount != null ? data.notificationCount : 0,
        apiusage_web_daily: data.webCallCountDay != null ? data.webCallCountDay : 0,
        apiusage_s2s_daily: data.s2sCallCountDay != null ? data.s2sCallCountDay : 0,
        apiusage_webhook_daily: data.webhookCallCountDay != null ? data.webhookCallCountDay : 0,
        apiusage_offboardevent_daily: data.offboardEventCountDay != null ? data.offboardEventCountDay : 0,
        apiusage_scheduledscripts_daily: data.scheduledScriptCallCountDay != null ? data.scheduledScriptCallCountDay : 0,

        purchases_count: data.purchaseCount != null ? data.purchaseCount : 0,
        purchases_total: data.revenue != null ? data.revenue : 0,
        purchases_itunes: data.revenuePlatform != null && data.revenuePlatform.itunes != null ? data.revenuePlatform.itunes : 0,
        purchases_googleplay: data.revenuePlatform != null && data.revenuePlatform.googlePlay != null ? data.revenuePlatform.googlePlay : 0,
        purchases_other: data.revenueOther != null ? data.revenueOther : 0,
    };

    // Calculate app status.
    if (result.isDeleted === true) result.app_status = AppStatus.DELETED;
    else if (result.isEnabled && result.isUserEnabled) result.app_status = AppStatus.ENABLED;
    else if (result.isEnabled === false) result.app_status = AppStatus.DISABLED;
    else result.app_status = AppStatus.DISABLED;

    // Calculate API per DAU.
    if (result.billing_total != null && result.users_dau != null && result.users_dau > 0) {
        result.users_api_per_dau = Math.floor(result.billing_total / result.users_dau);
    }

    // Calculate Total Offboard API Usage.
    if (result.apiusage_notifications != null && result.apiusage_webhook_daily != null && result.apiusage_s2s_daily != null && result.apiusage_offboardevent_daily != null && result.apiusage_scheduledscripts_daily != null) {
        result.apiusage_total = result.apiusage_notifications + result.apiusage_webhook_daily + result.apiusage_s2s_daily + result.apiusage_offboardevent_daily + result.apiusage_scheduledscripts_daily;
    }

    // Calculate Sessions per DAU.
    if (result.sessions_total != null && result.users_dau != null && result.users_dau > 0) {
        result.sessions_per_dau = Math.floor(result.sessions_total / result.users_dau);
    }

    // Calculate "other" revenue (excludes iTunes and GooglePlay).
    if (data.revenue) {
        Object.keys(data.revenue).forEach((key) => {
            const value = data.revenue[key] != null ? data.revenue[key] : 0;

            if (result.purchases_other != null && key && ["itunes", "googleplay"].includes(key.trim().toLowerCase())) {
                result.purchases_other -= value;
            }
        });

        if (result.purchases_other != null && result.purchases_other < 0) result.purchases_other = 0;
    }

    return result;
};

import { Trans } from "@lingui/macro";

const availableLanguages = [
    {
        label: <Trans>English</Trans>,
        code: "en",
    },
    {
        label: <Trans>French</Trans>,
        code: "fr",
    },
    {
        label: <Trans>Spanish</Trans>,
        code: "es",
    },
    {
        label: <Trans>Italian</Trans>,
        code: "it",
    },
    {
        label: <Trans>German</Trans>,
        code: "de",
    },
    {
        label: <Trans>Portuguese</Trans>,
        code: "pt",
    },
    {
        label: <Trans>Portuguese (Brazil)</Trans>,
        code: "pt_br",
    },
    {
        label: <Trans>Chinese</Trans>,
        code: "zh",
    },
    {
        label: <Trans>Chinese (Traditional)</Trans>,
        code: "zh_TW",
    },
    {
        label: <Trans>Chinese (Simplified)</Trans>,
        code: "zh_CN",
    },
    {
        label: <Trans>Dutch</Trans>,
        code: "nl",
    },
    {
        label: <Trans>Japanese</Trans>,
        code: "jp",
    },
    {
        label: <Trans>Korean</Trans>,
        code: "ko",
    },
    {
        label: <Trans>Vietnamese</Trans>,
        code: "vi",
    },
    {
        label: <Trans>Russian</Trans>,
        code: "ru",
    },
    {
        label: <Trans>Swedish</Trans>,
        code: "sv",
    },
    {
        label: <Trans>Danish</Trans>,
        code: "da",
    },
    {
        label: <Trans>Finnish</Trans>,
        code: "fi",
    },
    {
        label: <Trans>Norwegian</Trans>,
        code: "no",
    },
    {
        label: <Trans>Turkish</Trans>,
        code: "tr",
    },
    {
        label: <Trans>Greek</Trans>,
        code: "el",
    },
    {
        label: <Trans>Indonesian</Trans>,
        code: "id",
    },
    {
        label: <Trans>Malay</Trans>,
        code: "ms",
    },
    {
        label: <Trans>Thai</Trans>,
        code: "th",
    },
    {
        label: <Trans>Hungarian</Trans>,
        code: "hu",
    },
    {
        label: <Trans>Polish</Trans>,
        code: "pl",
    },
    {
        label: <Trans>Czech</Trans>,
        code: "cs",
    },
    {
        label: <Trans>Slovak</Trans>,
        code: "sk",
    },
    {
        label: <Trans>Ukrainian</Trans>,
        code: "uk",
    },
    {
        label: <Trans>Croatian</Trans>,
        code: "hr",
    },
    {
        label: <Trans>Catalan</Trans>,
        code: "ca",
    },
    {
        label: <Trans>Romanian</Trans>,
        code: "ro",
    },
    {
        label: <Trans>Hebrew</Trans>,
        code: "he",
    },
    {
        label: <Trans>Arabic</Trans>,
        code: "ar",
    },
    {
        label: <Trans>Punjabi</Trans>,
        code: "pa",
    },
    {
        label: <Trans>Hindi</Trans>,
        code: "hi",
    },
];
export default availableLanguages;

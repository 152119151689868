// Import libraries.
import React from "react";
import { Theme, Typography, Link } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

// Import types.
import { BulkActionDefinition, FilterData, FilterDefinition, FilterOptions, RowData } from "../../types";

// Import components.
import Tabs from "components/common/tabs";
import Button from "components/common/button/Button";
import FieldWrapper from "components/common/form/FieldWrapper";
import BasicFilter from "../../filters/BasicFilter";

// Import icons.
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// Import utilities.
import StringUtils from "utils/String";
import IconButton from "components/common/button/IconButton";

interface OWN_PROPS {
    id: string;

    className?: string;

    selectedRows: RowData[];

    filters?: FilterDefinition[];
    filterData?: FilterData | null;

    customJsonFilter?: {
        key?: string;

        query?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };

        sort?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };

        hint?: {
            recommendedKeys: string[];
            sample?: string;
            desc?: React.ReactNode;
        };

        active?: boolean;
    };

    bulkActions?: BulkActionDefinition[];

    onFilterChanged?: (name: string, value: any) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

interface STATE {
    customJsonOpen: boolean;
    customJsonQuery: string | null;
    customJsonSort: string | null;
    customJsonHint: string | null;
    activeCustomJsonFilterTabIndex: number | null;
}

class Toolbar extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        customJsonOpen: false,
        customJsonQuery: null,
        customJsonSort: null,
        customJsonHint: null,
        activeCustomJsonFilterTabIndex: null,
    };

    openCustomJsonFilter = () => {
        const { customJsonFilter } = this.props;
        const { customJsonQuery, customJsonSort, customJsonHint } = this.state;

        if (customJsonFilter) {
            this.setState({
                customJsonOpen: true,
                customJsonQuery: customJsonQuery != null ? customJsonQuery : customJsonFilter?.query?.sample || null,
                customJsonSort: customJsonSort != null ? customJsonSort : customJsonFilter?.sort?.sample || null,
                customJsonHint: customJsonHint != null ? customJsonHint : customJsonFilter?.hint?.sample || null,
            });
        }
    };

    closeCustomJsonFilter = () => {
        const { customJsonFilter } = this.props;

        if (customJsonFilter) {
            this.setState({ customJsonOpen: false });
        }
    };

    applyCustomJsonFilter = () => {
        const { customJsonFilter } = this.props;
        const { customJsonQuery, customJsonSort, customJsonHint } = this.state;

        if (customJsonFilter) {
            if (this.props.onFilterChanged) {
                this.props.onFilterChanged(customJsonFilter?.key || "customJson", {
                    query: customJsonQuery || null,
                    sort: customJsonSort || null,
                    hint: customJsonHint || null,
                });
            }
        }
    };

    clearCustomQuery = () => {
        const { customJsonFilter } = this.props;

        if (customJsonFilter) {
            this.setState({ customJsonQuery: customJsonFilter.query?.sample || null, customJsonSort: customJsonFilter.sort?.sample || null, customJsonHint: customJsonFilter.hint?.sample || null }, () => {
                if (this.props.onFilterChanged) {
                    this.props.onFilterChanged(this.props.customJsonFilter?.key || "customJson", null);
                }
            });
        }
    };

    handleCustomJsonFilterTabChange = (idx: number) => {
        this.setState({ activeCustomJsonFilterTabIndex: idx });
    };

    isCustomJsonFilterValid = () => {
        const { customJsonQuery, customJsonSort, customJsonHint } = this.state;

        if (!StringUtils.isNullOrEmpty(customJsonQuery)) {
            try {
                JSON.parse(customJsonQuery as string);
            } catch (error: any) {
                return false;
            }
        }

        if (!StringUtils.isNullOrEmpty(customJsonSort)) {
            try {
                JSON.parse(customJsonSort as string);
            } catch (error: any) {
                return false;
            }
        }

        if (!StringUtils.isNullOrEmpty(customJsonHint)) {
            try {
                JSON.parse(customJsonHint as string);
            } catch (error: any) {
                return false;
            }
        }
    };

    render() {
        const { classes, id, className, filters, filterData, customJsonFilter, bulkActions, selectedRows } = this.props;
        const { customJsonOpen, customJsonQuery, customJsonSort, customJsonHint, activeCustomJsonFilterTabIndex } = this.state;

        if (!filters && !bulkActions) return null;

        return (
            <>
                <div id={id} className={classnames(classes.root, className)}>
                    <div id={id + "-filters"} className={isMobile ? classes.mobileFilters : classes.filters}>
                        {filters &&
                            filters.map((filter: FilterDefinition, idx: number) => {
                                const hidden = typeof filter.hidden === "function" ? filter.hidden() : filter.hidden === true;

                                let disabled = typeof filter.disabled === "function" ? filter.disabled() : filter.disabled === true;

                                if (typeof filter.type === "string") {
                                    // If the "custom json" is open, then all other filters become disabled.
                                    if (customJsonOpen) {
                                        disabled = true;
                                    }

                                    // If the filter type is a string, then it's a basic filter (using a field type).
                                    return (
                                        <div key={idx} id={id + "-filter-" + idx} data-name={filter.id} style={filter.style} className={classnames({ [classes.visuallyHidden]: hidden })}>
                                            <BasicFilter
                                                name={filter.id}
                                                type={filter.type}
                                                label={filter.label}
                                                labelPosition={filter.labelPosition}
                                                labelAlignment={filter.labelAlignment}
                                                value={filterData != null ? filterData[filter.id] : null}
                                                required={["select"].includes(filter.type)}
                                                disabled={disabled}
                                                onChange={(name: string, value: any) => {
                                                    this.setState({ customJsonOpen: false, customJsonQuery: null, customJsonSort: null, customJsonHint: null }, () => {
                                                        if (this.props.onFilterChanged) {
                                                            this.props.onFilterChanged(name, value);
                                                        }
                                                    });
                                                }}
                                                options={{
                                                    ...filter.options,
                                                    endAdornment:
                                                        filter.type === "text" && filterData?.[filter.id] != null && filter.allowClearSearch ? (
                                                            <IconButton
                                                                id={"clear-search"}
                                                                onClick={() => {
                                                                    if (this.props.onFilterChanged) {
                                                                        this.props.onFilterChanged(filter.id, null);
                                                                    }
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        ) : undefined,
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    // Otherwise the filter is a custom filter.
                                    // In this case the "type" is actually a component class.
                                    const CustomFilter = filter.type as React.ComponentType<{ name: string; value?: any; onChange: (name: string, value: any) => void; options?: FilterOptions }>;

                                    return (
                                        <div key={idx} id={id + "-filter-" + idx} data-name={filter.id} style={filter.style} className={classnames({ [classes.visuallyHidden]: filter.hidden })}>
                                            <CustomFilter
                                                name={filter.id}
                                                value={filterData != null ? filterData[filter.id] : null}
                                                onChange={(name: string, value: any) => {
                                                    if (this.props.onFilterChanged) {
                                                        this.props.onFilterChanged(name, value);
                                                    }
                                                }}
                                                options={filter.options}
                                            />
                                        </div>
                                    );
                                }
                            })}

                        {customJsonFilter && (
                            <Button id={"custom-json-filter-toggle"} disabled={customJsonOpen} onClick={this.openCustomJsonFilter} endIcon={customJsonFilter.active ? <CheckIcon /> : undefined}>
                                <Trans>Refine...</Trans>
                            </Button>
                        )}
                    </div>

                    <div id={id + "-bulk-actions"} className={isMobile ? classes.mobileBulkActions : classes.bulkActions}>
                        {bulkActions &&
                            bulkActions.map((bulkAction: BulkActionDefinition, idx: number) => {
                                const hidden = typeof bulkAction.hidden === "function" ? bulkAction.hidden(selectedRows) : bulkAction.hidden === true;
                                const disabled = typeof bulkAction.disabled === "function" ? bulkAction.disabled(selectedRows) : bulkAction.disabled === true;

                                return (
                                    <div key={idx} id={id + "-bulk-action-" + idx} data-name={bulkAction.id} className={classnames({ [classes.visuallyHidden]: hidden, [classes.visuallyDisabled]: disabled })}>
                                        <div
                                            style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}
                                            onClick={() => {
                                                if (!disabled) bulkAction.action(selectedRows);
                                            }}
                                        >
                                            {bulkAction.render(selectedRows)}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {customJsonFilter && (customJsonFilter.query || customJsonFilter.sort || customJsonFilter.hint) && customJsonOpen && (
                    <span style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", padding: "0.3125em", borderStyle: "solid", borderColor: "inherit", borderRadius: "0.25em", borderWidth: "0.06125em" }}>
                        <Typography style={{ marginLeft: "0.3125em", fontWeight: "bold" }}>
                            <Trans>Advanced Filter</Trans>
                        </Typography>

                        <Tabs
                            orientation={"horizontal"}
                            configs={[
                                ...(customJsonFilter.query
                                    ? [
                                          {
                                              id: "query",
                                              label: <Trans>Query</Trans>,
                                              component: (
                                                  <div style={{ flex: "0 0 auto", display: "flex", alignItems: "flex-start" }}>
                                                      <div style={{ flex: "1 1 0px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                                          <FieldWrapper
                                                              name={"customJsonQuery"}
                                                              type={"monaco"}
                                                              value={customJsonQuery}
                                                              options={{
                                                                  mode: "json",
                                                                  rows: 8,
                                                                  recommendedKeys: customJsonFilter.query.recommendedKeys,
                                                              }}
                                                              onChange={(_name: string, value: any) => {
                                                                  this.setState({ customJsonQuery: value });
                                                              }}
                                                          />
                                                      </div>

                                                      <Typography style={{ flex: "1 1 0px" }}>
                                                          {customJsonFilter.query.desc || (
                                                              <Trans>
                                                                  Specify a custom filter using JSON syntax. Entity fields can be specified by prefixing with "data.". Accepts advanced logical ($or, $not) and comparison operations ($ne, $gt, $gte, $lt
                                                                  and $lte).
                                                              </Trans>
                                                          )}
                                                          &nbsp;
                                                          <Link href="https://getbraincloud.com/apidocs/apiref/#appendix-mongodbwherequeries" rel="noreferrer" target="_blank">
                                                              <Trans>Click for more info...</Trans>
                                                          </Link>
                                                      </Typography>
                                                  </div>
                                              ),
                                          },
                                      ]
                                    : []),
                                ...(customJsonFilter.sort
                                    ? [
                                          {
                                              id: "query",
                                              label: <Trans>Sort</Trans>,
                                              component: (
                                                  <div style={{ flex: "0 0 auto", display: "flex", alignItems: "flex-start" }}>
                                                      <div style={{ flex: "1 1 0px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                                          <FieldWrapper
                                                              name={"customJsonSort"}
                                                              type={"monaco"}
                                                              value={customJsonSort}
                                                              options={{
                                                                  mode: "json",
                                                                  rows: 8,
                                                                  recommendedKeys: customJsonFilter.sort.recommendedKeys,
                                                              }}
                                                              onChange={(_name: string, value: any) => {
                                                                  this.setState({ customJsonSort: value });
                                                              }}
                                                          />
                                                      </div>

                                                      <Typography style={{ flex: "1 1 0px" }}>
                                                          {customJsonFilter.sort.desc || <Trans>Specify a custom sort using JSON syntax. Entity fields can be specified by prefixing with "data.".</Trans>}
                                                          &nbsp;
                                                          <Link href="https://getbraincloud.com/apidocs/apiref/#appendix-mongodbwherequeries" rel="noreferrer" target="_blank">
                                                              <Trans>Click for more info...</Trans>
                                                          </Link>
                                                      </Typography>
                                                  </div>
                                              ),
                                          },
                                      ]
                                    : []),
                                ...(customJsonFilter.hint
                                    ? [
                                          {
                                              id: "query",
                                              label: <Trans>Hint</Trans>,
                                              component: (
                                                  <div style={{ flex: "0 0 auto", display: "flex", alignItems: "flex-start" }}>
                                                      <div style={{ flex: "1 1 0px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                                                          <FieldWrapper
                                                              name={"customJsonHint"}
                                                              type={"monaco"}
                                                              value={customJsonHint}
                                                              options={{
                                                                  mode: "json",
                                                                  rows: 8,
                                                                  recommendedKeys: customJsonFilter.hint.recommendedKeys,
                                                              }}
                                                              onChange={(_name: string, value: any) => {
                                                                  this.setState({ customJsonHint: value });
                                                              }}
                                                          />
                                                      </div>

                                                      <Typography style={{ flex: "1 1 0px" }}>
                                                          {customJsonFilter.hint.desc || <Trans>Specify a custom hint using JSON syntax. Entity fields can be specified by prefixing with "data.".</Trans>}
                                                          &nbsp;
                                                          <Link href="https://getbraincloud.com/apidocs/apiref/#appendix-mongodbwherequeries" rel="noreferrer" target="_blank">
                                                              <Trans>Click for more info...</Trans>
                                                          </Link>
                                                      </Typography>
                                                  </div>
                                              ),
                                          },
                                      ]
                                    : []),
                            ]}
                            value={activeCustomJsonFilterTabIndex}
                            onTabChanged={this.handleCustomJsonFilterTabChange}
                        />

                        <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                            <span style={{ flex: "0 0 auto" }}>
                                <Button id={"clear-custom-query"} onClick={this.clearCustomQuery}>
                                    <Trans>Clear</Trans>
                                </Button>
                            </span>

                            <span style={{ flex: "1 1 auto", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <Button id={"close-custom-query"} onClick={this.closeCustomJsonFilter}>
                                    <Trans>Close</Trans>
                                </Button>

                                <Button id={"apply-custom-query"} onClick={this.applyCustomJsonFilter} disabled={this.isCustomJsonFilterValid()}>
                                    <Trans>Apply</Trans>
                                </Button>
                            </span>
                        </span>
                    </span>
                )}
            </>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "inherit",
            padding: "0.3125em",
            paddingLeft: "0.625em",
            paddingRight: "0.625em",
            "@media(max-width: 45em)": {
                display: "block",
            },
        },
        filters: {
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& > *:not(:first-child)": {
                marginLeft: "0.3125em",
            },
        },
        mobileFilters: {
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            "& > *:not(:first-child)": {
                marginTop: "0.3125em",
            },
        },
        bulkActions: {
            flex: "0 0 auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            marginLeft: "0.3125em",
            "& > *:not(:first-child)": {
                marginLeft: "0.3125em",
            },
        },
        mobileBulkActions: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            "& > *": {
                marginTop: "0.3125em",
            },
        },
        visuallyHidden: {
            display: "none",
        },
        visuallyDisabled: {
            cursor: "not-allowed",
        },
    });

export default withStyles(styles)(Toolbar);
